import { useCallback, useMemo } from "react";
import { getArticleById } from "../../global/utils/models/menu/Menu";
import { initOrderArticle } from "../../global/utils/models/order/OrderArticle";
import { ArticleType } from "../../global/utils/models/menu/Article";
import { createPath, routes } from "../../global/utils/config";
import OrderArticleSelectorPage from "../pages/OrderArticleSelectorPage/OrderArticleSelectorPage";
import SlideUpTransition from "../../global/components/SlideUpTransition";
import { useDialog } from "../../global/utils/dialog/DialogProvider";
import { useLocation } from "react-router";
import useAddOrderArticleToShoppingCart from "../../global/utils/order/useAddOrderArticleToShoppingCart";
import EancodeUnknownDialog from "../components/BarCodeScanner/EancodeUnknownDialog";
import { setProductAddedByBarcodeDialog } from "../../global/utils/redux/kioskSlice";
import store, { useAppDispatch, useAppSelector } from "../../global/utils/redux/store";
import useEmployeeCodes from "../hooks/useEmployeeCodes";
import _ from "lodash";
import useAppNavigate from "../../global/utils/useAppNavigate";
import useSalesareaType, { SalesareaType } from "../../global/utils/hooks/useSalesareaType";
import { selectEanCodeMapping } from "../../global/utils/redux/selectors/selectEanCodeMapping";
import { selectArticlesMap } from "../../global/utils/redux/selectors/selectArticlesMap";
import DuplicateEancodeDialog from "../components/BarCodeScanner/DuplicateEancodeDialog";
import { AgeCheckResult } from "../../global/utils/ageCheck/ageCheckUtils.ts";
import { useAgeCheck } from "../../global/components/AgeCheckDialog.tsx";

export default function useBarCodeChecker() {
  const articlesMap = useAppSelector(selectArticlesMap);
  const { openDialog } = useDialog();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const salesareaType = useSalesareaType();
  const navigate = useAppNavigate();
  const addOrderArticleToShoppingCart = useAddOrderArticleToShoppingCart();
  const isOnHomePage = useMemo(() => {
    return pathname === createPath(routes.kiosk.rootPath);
  }, [pathname]);
  const checkAge = useAgeCheck();

  const employeeCodes = useEmployeeCodes();

  const addProductByBarCode = useCallback(
    async (buffer: string) => {
      const eanCodeMapping = selectEanCodeMapping(store.getState());
      let article = getArticleById(articlesMap, eanCodeMapping.eanCodes?.[buffer]?.articleId ?? 0);

      if (article) {
        const selectedEanCode = article.eanCodes?.find((eanCode) => {
          return buffer === eanCode.id;
        });

        if (selectedEanCode && "price" in selectedEanCode && selectedEanCode["price"]) {
          const copyArticle = _.cloneDeep(article);
          copyArticle.price = parseFloat(selectedEanCode.price);
          // copyArticle.originalPrice = copyArticle.price;
          copyArticle.hasCustomizedPriceAndName = true;

          article = copyArticle;
        }

        const orderArticle = initOrderArticle(articlesMap, article, 1);
        orderArticle.eanCode = selectedEanCode;

        const ageCheckResult = await checkAge(orderArticle);
        if (ageCheckResult === AgeCheckResult.Denied) {
          return;
        }

        if (orderArticle.orderOptionGroups.length > 0 || salesareaType === SalesareaType.CATALOG) {
          if (!(article.isOutOfStock || article.type === ArticleType.InfoDismissable)) {
            if (isOnHomePage) {
              navigate(routes.kiosk.menuPath);
              openDialog({
                children: <OrderArticleSelectorPage article={article} />,
                fullScreen: "almostFullScreen",
                TransitionComponent: SlideUpTransition,
              });
            } else {
              openDialog({
                children: <OrderArticleSelectorPage article={article} />,
                fullScreen: "almostFullScreen",
                TransitionComponent: SlideUpTransition,
              });
            }
          }
        } else {
          if (isOnHomePage) {
            navigate(routes.kiosk.menuPath);
          }
          dispatch(
            setProductAddedByBarcodeDialog({
              isOpen: true,
              timer: setTimeout(() => {
                dispatch(setProductAddedByBarcodeDialog({ isOpen: false }));
              }, 10000),
            })
          );
          addOrderArticleToShoppingCart(orderArticle).catch((err) => console.log(err));
        }
      } else {
        // eancode unknown!
        if (!store.getState().global.eancodeUnknownDialogIsOpen) {
          openDialog({
            children: <EancodeUnknownDialog eancode={buffer} />,
            TransitionComponent: SlideUpTransition,
            disabledBackdropClick: Object.keys(employeeCodes).length > 0,
          });
        }
      }
    },
    [
      articlesMap,
      checkAge,
      salesareaType,
      isOnHomePage,
      navigate,
      openDialog,
      dispatch,
      addOrderArticleToShoppingCart,
      employeeCodes,
    ]
  );

  window.addProductByBarCode = addProductByBarCode;
  return (buffer: string): boolean => {
    const eanCode = buffer.replace(/^F+/ims, "");
    const { eanCodes, duplicateEans } = selectEanCodeMapping(store.getState());

    if (eanCodes[buffer]) {
      addProductByBarCode(buffer);
      return true;
    } else if (eanCodes[eanCode]) {
      addProductByBarCode(eanCode);
      return true;
    } else if (duplicateEans[buffer] || duplicateEans[eanCode]) {
      openDialog({
        children: <DuplicateEancodeDialog eancode={eanCode} />,
        TransitionComponent: SlideUpTransition,
        disabledBackdropClick: Object.keys(employeeCodes).length > 0,
      });
      return true;
    }
    return false;
  };
}
