import store, { useAppSelector } from "../../../../global/utils/redux/store.tsx";
import { useEffect, useMemo } from "react";
import { useDialog } from "../../../../global/utils/dialog/DialogProvider.tsx";
import { selectArticlesMap } from "../../../../global/utils/redux/selectors/selectArticlesMap.ts";
import { getArticlesById } from "../../../../global/utils/models/menu/Menu.ts";
import AddDiscountedProductDialog from "./AddDiscountedProductDialog.tsx";
import OrderArticle, {
  initOrderArticle,
  OrderArticleOrigin,
} from "../../../../global/utils/models/order/OrderArticle.ts";
import { voucherOrderArticleConfirmed, VoucherV2 } from "../../../../global/utils/vouchersV2/vouchersV2Slice.tsx";
import OrderArticleSelectorPage from "../../../pages/OrderArticleSelectorPage/OrderArticleSelectorPage.tsx";
import SlideUpTransition from "../../../../global/components/SlideUpTransition.tsx";
import useAddOrderArticleToShoppingCart from "../../../../global/utils/order/useAddOrderArticleToShoppingCart.tsx";
import _ from "lodash";
import { calculateDiscountForVoucher } from "../../../../global/utils/vouchersV2/selectors/calculateDiscountForVoucher.ts";
import { selectSalesAreaPriceLineId } from "../../../../global/utils/useSalesAreaPriceLineId.ts";
import { selectArticleArticlegroupsMapUnfiltered } from "../../../../global/utils/redux/selectors/selectArticleArticlegroupsMapUnfiltered.ts";
import { selectArticlegroupsMap } from "../../../../global/utils/redux/selectors/selectArticlegroupsMap.ts";
import buildDiscountsRecursively from "../../../../global/utils/vouchersV2/selectors/buildDiscountsRecursively.ts";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { selectArticlesApiIdMap } from "../../../../global/utils/redux/selectors/selectArticlesApiIdMap.ts";
import Article from "../../../../global/utils/models/menu/Article.ts";

function processVouchers(voucherV2: VoucherV2, discountedProducts: VoucherV2[]) {
  if (
    voucherV2.voucherdef.discountType === "ADD_DISCOUNTED_PRODUCT" ||
    ((voucherV2.voucherdef.discountType === "PERCENTAGE_OFF_PRODUCT" ||
      voucherV2.voucherdef.discountType === "AMOUNT_OFF_PRODUCT") &&
      voucherV2.voucherdef.maxChooseItems > 0)
  ) {
    if (
      voucherV2.voucher.number_of_times >
      (_.chain(voucherV2.orderArticleUuids)
        .values()
        .map("count")
        .value()
        ?.reduce((total, count) => total + count, 0) ?? 0)
    ) {
      discountedProducts.push(voucherV2);
    }
  }

  voucherV2.subVouchers?.forEach((subVoucher) => {
    processVouchers(subVoucher, discountedProducts);
  });
}

export default function VouchersAddDiscountedProducts() {
  const vouchers = useAppSelector((state) => state.vouchersV2.vouchers);
  const discountedProducts = useMemo(() => {
    const discountedProducts: VoucherV2[] = [];
    vouchers.forEach((voucherV2) => {
      processVouchers(voucherV2, discountedProducts);
    });
    return discountedProducts;
  }, [vouchers]);
  const { openDialog, closeDialog } = useDialog();
  const priceLineId = useAppSelector(selectSalesAreaPriceLineId);
  const articleArticlegroupsMap = useAppSelector(selectArticleArticlegroupsMapUnfiltered);
  const articlegroupsMap = useAppSelector(selectArticlegroupsMap);
  const intl = useIntl();

  const addOrderArticleToShoppingCart = useAddOrderArticleToShoppingCart({ allowUpsell: false });
  useEffect(() => {
    if (discountedProducts.length > 0) {
      const voucherV2 = discountedProducts[0];

      const items = store.getState().shoppingCart.items;
      const currentDiscounts: {
        [orderArticleUuid: string]: {
          discount: number;
        }[];
      } = buildDiscountsRecursively(items);

      if (
        voucherV2.voucherdef.discountType == "ADD_DISCOUNTED_PRODUCT" ||
        ((voucherV2.voucherdef.discountType == "PERCENTAGE_OFF_PRODUCT" ||
          voucherV2.voucherdef.discountType == "AMOUNT_OFF_PRODUCT") &&
          voucherV2.voucherdef.maxChooseItems > 0)
      ) {
        const discounts = calculateDiscountForVoucher(
          voucherV2,
          items,
          priceLineId,
          currentDiscounts,
          articleArticlegroupsMap,
          articlegroupsMap
        );

        const potentialDiscount = _.chain(discounts).values().flattenDepth(1).sumBy("discount").value() > 0;
        if (potentialDiscount) {
          console.log("Items already in basket which discount applies on.");
          toast.success(intl.formatMessage({ id: "Discount applied" }));

          const orderArticles: OrderArticle[] = [];
          const items = store.getState().shoppingCart.items;

          Object.keys(discounts).forEach((uuid) => {
            const orderArticleIndex = items.findIndex((item) => item.uuid == uuid);
            if (orderArticleIndex >= 0) {
              orderArticles.push(items[orderArticleIndex]);
            }
          });

          store.dispatch(voucherOrderArticleConfirmed({ voucher: voucherV2, orderArticles }));
          return;
        }

        const articleIds =
          voucherV2.voucherdef.discountType == "ADD_DISCOUNTED_PRODUCT"
            ? voucherV2.voucherdef.free_products_v2.map((p) => p.id)
            : _.merge(
                voucherV2.voucherdef.included_products.map((p) => p.id),
                voucherV2.voucherdef.discounted_products.map((p) => p.id)
              );

        // const productIdsWhichMayBeOptions =
        //   voucherV2.voucherdef.discountType == "ADD_DISCOUNTED_PRODUCT"
        //     ? voucherV2.voucherdef.freeProducts_JSON
        //     : voucherV2.voucherdef.included_products.map((p) => p.id);

        const articlesMap = selectArticlesMap(store.getState());

        // const articlesWithPotentialOptions = getArticlesById(articlesMap, productIdsWhichMayBeOptions).forEach(
        //   (article) => {
        //     getOptionGroupsByArticleId();
        //   }
        // );
        let articles = getArticlesById(articlesMap, articleIds).filter((article) => article.apiId2 == null);

        if (voucherV2.voucherdef.discountType == "ADD_DISCOUNTED_PRODUCT") {
          if (articles.length === 0) {
            const articlesByApiId = selectArticlesApiIdMap(store.getState());
            articles = voucherV2.voucherdef.free_products_v2
              .map((product) => {
                const article = product.apiId1 ? articlesByApiId[product.apiId1] : null;
                if (article && article.apiId2 == null) {
                  return article;
                } else {
                  return null;
                }
              })
              .filter((a) => a) as Article[];
          }
        }

        if (articles.length > 1) {
          openDialog({
            children: <AddDiscountedProductDialog articles={articles} voucher={voucherV2} />,
            fullScreen: "almostFullScreen",
            disabledBackdropClick: true,
          });
        } else if (articles[0]?.optionGroupIds.length > 0) {
          openDialog({
            children: (
              <OrderArticleSelectorPage
                article={articles[0]}
                isUpselled={true}
                fixedCount={1}
                isPiggyReward={true}
                onOrderArticleOk={(orderArticle) => {
                  addOrderArticleToShoppingCart(orderArticle).then(() => {
                    store.dispatch(voucherOrderArticleConfirmed({ voucher: voucherV2, orderArticles: [orderArticle] }));
                    closeDialog();
                    closeDialog();
                  });
                }}
              />
            ),
            fullScreen: "almostFullScreen",
            TransitionComponent: SlideUpTransition,
          });
        } else if (articles.length === 1 && articles[0]?.optionGroupIds.length === 0) {
          const orderArticle = initOrderArticle(
            articlesMap,
            articles[0],
            1,
            "",
            false,
            false,
            false,
            OrderArticleOrigin.MENU,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined
          );

          addOrderArticleToShoppingCart(orderArticle).then(() => {
            store.dispatch(voucherOrderArticleConfirmed({ voucher: voucherV2, orderArticles: [orderArticle] }));
          });
        } else {
          toast.success(
            "The discount will be applied as soon as there is an item in your shopping cart that qualifies."
          );
          // store.dispatch(voucherOrderArticleConfirmed({ voucher: voucherV2 }));
        }
      } else {
        // store.dispatch(voucherOrderArticleConfirmed({ voucher: voucherV2 }));
      }
    }
  }, [
    addOrderArticleToShoppingCart,
    articleArticlegroupsMap,
    articlegroupsMap,
    closeDialog,
    discountedProducts,
    intl,
    openDialog,
    priceLineId,
  ]);
  return null;
}
